import React from "react"
import styled, { css } from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  font-family: "Arimo", sans-serif;
  padding: 50px 20px;
  max-width: 800px;
  margin: 0 auto;

  @media (min-width: 700px) {
    padding: 50px50px;
  }
`

const spacing = css`
  margin: 10px 0;
`

const H1 = styled.h1`
  font-size: 2em;
  ${spacing}
`

const H2 = styled.h2`
  font-size: 1.5em;
  ${spacing}
`

const H3 = styled.h3`
  font-size: 1.25em;
  ${spacing}
`

const Ul = styled.ul`
  list-style: circle !important;
  padding-left: 20px;
  ${spacing}

  li {
    text-indent: 10px;
    ${spacing}
  }
`

const P = styled.p`
  ${spacing}
`

const Bold = styled.strong`
  font-weight: bold;
`

const A = styled.a`
  color: ${props => props.theme.colors.yellow};
  &:hover {
    color: white;
  }
`

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <H1>Privacy Policy</H1>
      <P>Last updated: August 25, 2020</P>
      <P>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </P>
      <P>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </P>
      <H1>Interpretation and Definitions</H1>
      <H2>Interpretation</H2>
      <P>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </P>
      <H2>Definitions</H2>
      <P>For the purposes of this Privacy Policy:</P>
      <Ul>
        <li>
          <P>
            <Bold>Account</Bold> means a unique account created for You to
            access our Service or parts of our Service.
          </P>
        </li>
        <li>
          <P>
            <Bold>Business</Bold>, for the purpose of the CCPA (California
            Consumer Privacy Act), refers to the Company as the legal entity
            that collects Consumers' personal information and determines the
            purposes and means of the processing of Consumers' personal
            information, or on behalf of which such information is collected and
            that alone, or jointly with others, determines the purposes and
            means of the processing of consumers' personal information, that
            does business in the State of California.
          </P>
        </li>
        <li>
          <P>
            <Bold>Company</Bold> (referred to as either &quot;the Company&quot;,
            &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
            refers to Torchlight Press, 295 Rue de la Montagne, Montreal, QC H3C
            4K4.
          </P>
          <P>
            For the purpose of the GDPR, the Company is the Data Controller.
          </P>
        </li>
        <li>
          <P>
            <Bold>Consumer</Bold>, for the purpose of the CCPA (California
            Consumer Privacy Act), means a natural person who is a California
            resident. A resident, as defined in the law, includes (1) every
            individual who is in the USA for other than a temporary or
            transitory purpose, and (2) every individual who is domiciled in the
            USA who is outside the USA for a temporary or transitory purpose.
          </P>
        </li>
        <li>
          <P>
            <Bold>Cookies</Bold> are small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            the details of Your browsing history on that website among its many
            uses.
          </P>
        </li>
        <li>
          <P>
            <Bold>Country</Bold> refers to: Quebec, Canada
          </P>
        </li>
        <li>
          <P>
            <Bold>Data Controller</Bold>, for the purposes of the GDPR (General
            Data Protection Regulation), refers to the Company as the legal
            person which alone or jointly with others determines the purposes
            and means of the processing of Personal Data.
          </P>
        </li>
        <li>
          <P>
            <Bold>Device</Bold> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </P>
        </li>
        <li>
          <P>
            <Bold>Do Not Track</Bold> (DNT) is a concept that has been promoted
            by US regulatory authorities, in particular the U.S. Federal Trade
            Commission (FTC), for the Internet industry to develop and implement
            a mechanism for allowing internet users to control the tracking of
            their online activities across websites.
          </P>
        </li>
        <li>
          <P>
            <Bold>Personal Data</Bold> is any information that relates to an
            identified or identifiable individual.
          </P>
          <P>
            For the purposes for GDPR, Personal Data means any information
            relating to You such as a name, an identification number, location
            data, online identifier or to one or more factors specific to the
            physical, physiological, genetic, mental, economic, cultural or
            social identity.
          </P>
          <P>
            For the purposes of the CCPA, Personal Data means any information
            that identifies, relates to, describes or is capable of being
            associated with, or could reasonably be linked, directly or
            indirectly, with You.
          </P>
        </li>
        <li>
          <P>
            <Bold>Sale</Bold>, for the purpose of the CCPA (California Consumer
            Privacy Act), means selling, renting, releasing, disclosing,
            disseminating, making available, transferring, or otherwise
            communicating orally, in writing, or by electronic or other means, a
            Consumer's Personal information to another business or a third party
            for monetary or other valuable consideration.
          </P>
        </li>
        <li>
          <P>
            <Bold>Service</Bold> refers to the Website.
          </P>
        </li>
        <li>
          <P>
            <Bold>Service Provider</Bold> means any natural or legal person who
            processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used. For the purpose of the
            GDPR, Service Providers are considered Data Processors.
          </P>
        </li>
        <li>
          <P>
            <Bold>Third-party Social Media Service</Bold> refers to any website
            or any social network website through which a User can log in or
            create an account to use the Service.
          </P>
        </li>
        <li>
          <P>
            <Bold>Usage Data</Bold> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </P>
        </li>
        <li>
          <P>
            <Bold>Website</Bold> refers to Torchlight Press, accessible from{" "}
            <A
              href="https://torchlight.press"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://torchlight.press
            </A>
          </P>
        </li>
        <li>
          <P>
            <Bold>You</Bold> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </P>
          <P>
            Under GDPR (General Data Protection Regulation), You can be referred
            to as the Data Subject or as the User as you are the individual
            using the Service.
          </P>
        </li>
      </Ul>
      <H1>Collecting and Using Your Personal Data</H1>
      <H2>Types of Data Collected</H2>
      <H3>Personal Data</H3>
      <P>
        For a better experience, and in order to provide our Service, we may
        require you to provide us, either directly or indirectly, with certain
        personally identifiable information, including but not limited to user
        name, email, and Oauth2 credentials. The information that we request
        will be retained by us and used as described in this privacy policy.
      </P>
      <P>
        The app uses third party services that may collect information used to
        identify you. Links to privacy policies of third party service providers
        used by Torchlight Press:
      </P>
      <Ul>
        <li>
          <A href="https://policies.google.com/privacy">Google Sign-in</A>
        </li>
      </Ul>
      <P>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </P>
      <Ul>
        <li>
          <P>Email address</P>
        </li>
        <li>
          <P>First name and last name</P>
        </li>
        <li>
          <P>Phone number</P>
        </li>
        <li>
          <P>Address, State, Province, ZIP/Postal code, City</P>
        </li>
        <li>
          <P>Usage Data</P>
        </li>
      </Ul>
      <H3>Usage Data</H3>
      <P>Usage Data is collected automatically when using the Service.</P>
      <P>
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </P>
      <P>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </P>
      <P>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </P>
      <H3>Tracking Technologies and Cookies</H3>
      <P>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service.
      </P>
      <P>
        You can instruct Your browser to refuse all Cookies or to indicate when
        a Cookie is being sent. However, if You do not accept Cookies, You may
        not be able to use some parts of our Service.
      </P>
      <P>
        Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
        Persistent Cookies remain on your personal computer or mobile device
        when You go offline, while Session Cookies are deleted as soon as You
        close your web browser. Learn more about cookies:{" "}
        <A
          href="https://www.termsfeed.com/blog/cookies/"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          All About Cookies
        </A>
        .
      </P>
      <P>
        We use both session and persistent Cookies for the purposes set out
        below:
      </P>
      <Ul>
        <li>
          <P>
            <Bold>Necessary / Essential Cookies</Bold>
          </P>
          <P>Type: Session Cookies</P>
          <P>Administered by: Us</P>
          <P>
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </P>
        </li>
        <li>
          <P>
            <Bold>Cookies Policy / Notice Acceptance Cookies</Bold>
          </P>
          <P>Type: Persistent Cookies</P>
          <P>Administered by: Us</P>
          <P>
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </P>
        </li>
        <li>
          <P>
            <Bold>Functionality Cookies</Bold>
          </P>
          <P>Type: Persistent Cookies</P>
          <P>Administered by: Us</P>
          <P>
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </P>
        </li>
        <li>
          <P>
            <Bold>Tracking and Performance Cookies</Bold>
          </P>
          <P>Type: Persistent Cookies</P>
          <P>Administered by: Third-Parties</P>
          <P>
            Purpose: These Cookies are used to track information about traffic
            to the Website and how users use the Website. The information
            gathered via these Cookies may directly or indirectly identify you
            as an individual visitor. This is because the information collected
            is typically linked to a pseudonymous identifier associated with the
            device you use to access the Website. We may also use these Cookies
            to test new pages, features or new functionality of the Website to
            see how our users react to them.
          </P>
        </li>
      </Ul>
      <P>
        For more information about the cookies we use and your choices regarding
        cookies, please visit our Cookies Policy or the Cookies section of our
        Privacy Policy.
      </P>
      <H2>Use of Your Personal Data</H2>
      <P>The Company may use Personal Data for the following purposes:</P>
      <Ul>
        <li>
          <Bold>To provide and maintain our Service</Bold>, including to monitor
          the usage of our Service.
        </li>
        <li>
          <Bold>To manage Your Account:</Bold> to manage Your registration as a
          user of the Service. The Personal Data You provide can give You access
          to different functionalities of the Service that are available to You
          as a registered user.
        </li>
        <li>
          <Bold>For the performance of a contract:</Bold> the development,
          compliance and undertaking of the purchase contract for the products,
          items or services You have purchased or of any other contract with Us
          through the Service.
        </li>
        <li>
          <Bold>To contact You:</Bold> To contact You by email, telephone calls,
          SMS, or other equivalent forms of electronic communication, such as a
          mobile application's push notifications regarding updates or
          informative communications related to the functionalities, products or
          contracted services, including the security updates, when necessary or
          reasonable for their implementation.
        </li>
        <li>
          <Bold>To provide You</Bold> with news, special offers and general
          information about other goods, services and events which we offer that
          are similar to those that you have already purchased or enquired about
          unless You have opted not to receive such information.
        </li>
        <li>
          <Bold>To manage Your requests:</Bold> To attend and manage Your
          requests to Us.
        </li>
      </Ul>
      <P>We may share your personal information in the following situations:</P>
      <Ul>
        <li>
          <Bold>With Service Providers:</Bold> We may share Your personal
          information with Service Providers to monitor and analyze the use of
          our Service, to advertise on third party websites to You after You
          visited our Service, for payment processing, to contact You.
        </li>
        <li>
          <Bold>For Business transfers:</Bold> We may share or transfer Your
          personal information in connection with, or during negotiations of,
          any merger, sale of Company assets, financing, or acquisition of all
          or a portion of our business to another company.
        </li>
        <li>
          <Bold>With Affiliates:</Bold> We may share Your information with Our
          affiliates, in which case we will require those affiliates to honor
          this Privacy Policy. Affiliates include Our parent company and any
          other subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us.
        </li>
        <li>
          <Bold>With Business partners:</Bold> We may share Your information
          with Our business partners to offer You certain products, services or
          promotions.
        </li>
        <li>
          <Bold>With other users:</Bold> when You share personal information or
          otherwise interact in the public areas with other users, such
          information may be viewed by all users and may be publicly distributed
          outside. If You interact with other users or register through a
          Third-Party Social Media Service, Your contacts on the Third-Party
          Social Media Service may see Your name, profile, pictures and
          description of Your activity. Similarly, other users will be able to
          view descriptions of Your activity, communicate with You and view Your
          profile.
        </li>
      </Ul>
      <H2>Retention of Your Personal Data</H2>
      <P>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </P>
      <P>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </P>
      <H2>Transfer of Your Personal Data</H2>
      <P>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </P>
      <P>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </P>
      <P>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </P>
      <H2>Disclosure of Your Personal Data</H2>
      <H3>Business Transactions</H3>
      <P>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </P>
      <H3>Law enforcement</H3>
      <P>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </P>
      <H3>Other legal requirements</H3>
      <P>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </P>
      <Ul>
        <li>Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of the Company</li>
        <li>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          Protect the personal safety of Users of the Service or the public
        </li>
        <li>Protect against legal liability</li>
      </Ul>
      <H2>Security of Your Personal Data</H2>
      <P>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </P>
      <H1>Detailed Information on the Processing of Your Personal Data</H1>
      <P>
        Service Providers have access to Your Personal Data only to perform
        their tasks on Our behalf and are obligated not to disclose or use it
        for any other purpose.
      </P>
      <H2>Analytics</H2>
      <P>
        We may use third-party Service providers to monitor and analyze the use
        of our Service.
      </P>
      <Ul>
        <li>
          <P>
            <Bold>Google Analytics</Bold>
          </P>
          <P>
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualize and personalize the ads of its own advertising
            network.
          </P>
          <P>
            You can opt-out of having made your activity on the Service
            available to Google Analytics by installing the Google Analytics
            opt-out browser add-on. The add-on prevents the Google Analytics
            JavaScript (ga.js, analytics.js and dc.js) from sharing information
            with Google Analytics about visits activity.
          </P>
          <P>
            For more information on the privacy practices of Google, please
            visit the Google Privacy &amp; Terms web page:{" "}
            <A
              href="https://policies.google.com/privacy"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/privacy
            </A>
          </P>
        </li>
      </Ul>
      <H2>Email Marketing</H2>
      <P>
        We may use Your Personal Data to contact You with newsletters, marketing
        or promotional materials and other information that may be of interest
        to You. You may opt-out of receiving any, or all, of these
        communications from Us by following the unsubscribe link or instructions
        provided in any email We send or by contacting Us.
      </P>
      <P>
        We may use Email Marketing Service Providers to manage and send emails
        to You.
      </P>
      <H2>Payments</H2>
      <P>
        We may provide paid products and/or services within the Service. In that
        case, we may use third-party services for payment processing (e.g.
        payment processors).
      </P>
      <P>
        We will not store or collect Your payment card details. That information
        is provided directly to Our third-party payment processors whose use of
        Your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </P>
      <Ul>
        <li>
          <P>
            <Bold>Stripe</Bold>
          </P>
          <P>
            Their Privacy Policy can be viewed at{" "}
            <A
              href="https://stripe.com/us/privacy"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://stripe.com/us/privacy
            </A>
          </P>
        </li>
        <li>
          <P>
            <Bold>PayPal</Bold>
          </P>
          <P>
            Their Privacy Policy can be viewed at{" "}
            <A
              href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://www.paypal.com/webapps/mpp/ua/privacy-full
            </A>
          </P>
        </li>
      </Ul>
      <H2>Behavioral Remarketing</H2>
      <P>
        The Company uses remarketing services to advertise on third party
        websites to You after You visited our Service. We and Our third-party
        vendors use cookies to inform, optimize and serve ads based on Your past
        visits to our Service.
      </P>
      <Ul>
        <li>
          <P>
            <Bold>Google Ads (AdWords)</Bold>
          </P>
          <P>
            Google Ads (AdWords) remarketing service is provided by Google Inc.
          </P>
          <P>
            You can opt-out of Google Analytics for Display Advertising and
            customise the Google Display Network ads by visiting the Google Ads
            Settings page:{" "}
            <A
              href="http://www.google.com/settings/ads"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              http://www.google.com/settings/ads
            </A>
          </P>
          <P>
            Google also recommends installing the Google Analytics Opt-out
            Browser Add-on -{" "}
            <A
              href="https://tools.google.com/dlpage/gaoptout"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://tools.google.com/dlpage/gaoptout
            </A>{" "}
            - for your web browser. Google Analytics Opt-out Browser Add-on
            provides visitors with the ability to prevent their data from being
            collected and used by Google Analytics.
          </P>
          <P>
            For more information on the privacy practices of Google, please
            visit the Google Privacy &amp; Terms web page:{" "}
            <A
              href="https://policies.google.com/privacy"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://policies.google.com/privacy
            </A>
          </P>
        </li>
        <li>
          <P>
            <Bold>Twitter</Bold>
          </P>
          <P>Twitter remarketing service is provided by Twitter Inc.</P>
          <P>
            You can opt-out from Twitter's interest-based ads by following their
            instructions:{" "}
            <A
              href="https://support.twitter.com/articles/20170405"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://support.twitter.com/articles/20170405
            </A>
          </P>
          <P>
            You can learn more about the privacy practices and policies of
            Twitter by visiting their Privacy Policy page:{" "}
            <A
              href="https://twitter.com/privacy"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://twitter.com/privacy
            </A>
          </P>
        </li>
        <li>
          <P>
            <Bold>Facebook</Bold>
          </P>
          <P>Facebook remarketing service is provided by Facebook Inc.</P>
          <P>
            You can learn more about interest-based advertising from Facebook by
            visiting this page:{" "}
            <A
              href="https://www.facebook.com/help/164968693837950"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://www.facebook.com/help/164968693837950
            </A>
          </P>
          <P>
            To opt-out from Facebook's interest-based ads, follow these
            instructions from Facebook:{" "}
            <A
              href="https://www.facebook.com/help/568137493302217"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://www.facebook.com/help/568137493302217
            </A>
          </P>
          <P>
            Facebook adheres to the Self-Regulatory Principles for Online
            Behavioural Advertising established by the Digital Advertising
            Alliance. You can also opt-out from Facebook and other participating
            companies through the Digital Advertising Alliance in the USA{" "}
            <A
              href="http://www.aboutads.info/choices/"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              http://www.aboutads.info/choices/
            </A>
            , the Digital Advertising Alliance of Canada in Canada{" "}
            <A
              href="http://youradchoices.ca/"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              http://youradchoices.ca/
            </A>{" "}
            or the European Interactive Digital Advertising Alliance in Europe{" "}
            <A
              href="http://www.youronlinechoices.eu/"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              http://www.youronlinechoices.eu/
            </A>
            , or opt-out using your mobile device settings.
          </P>
          <P>
            For more information on the privacy practices of Facebook, please
            visit Facebook's Data Policy:{" "}
            <A
              href="https://www.facebook.com/privacy/explanation"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://www.facebook.com/privacy/explanation
            </A>
          </P>
        </li>
        <li>
          <P>
            <Bold>Pinterest</Bold>
          </P>
          <P>Pinterest remarketing service is provided by Pinterest Inc.</P>
          <P>
            You can opt-out from Pinterest's interest-based ads by enabling the
            &quot;Do Not Track&quot; functionality of your web browser or by
            following Pinterest instructions:{" "}
            <A
              href="http://help.pinterest.com/en/articles/personalization-and-data"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              http://help.pinterest.com/en/articles/personalization-and-data
            </A>
          </P>
          <P>
            You can learn more about the privacy practices and policies of
            Pinterest by visiting their Privacy Policy page:{" "}
            <A
              href="https://about.pinterest.com/en/privacy-policy"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://about.pinterest.com/en/privacy-policy
            </A>
          </P>
        </li>
      </Ul>
      <H2>Usage, Performance and Miscellaneous</H2>
      <P>
        We may use third-party Service Providers to provide better improvement
        of our Service.
      </P>
      <Ul>
        <li>
          <P>
            <Bold>Invisible reCAPTCHA</Bold>
          </P>
          <P>
            We use an invisible captcha service named reCAPTCHA. reCAPTCHA is
            operated by Google.
          </P>
          <P>
            The reCAPTCHA service may collect information from You and from Your
            Device for security purposes.
          </P>
          <P>
            The information gathered by reCAPTCHA is held in accordance with the
            Privacy Policy of Google:{" "}
            <A
              href="https://www.google.com/intl/en/policies/privacy/"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              https://www.google.com/intl/en/policies/privacy/
            </A>
          </P>
        </li>
      </Ul>
      <H1>GDPR Privacy</H1>
      <H2>Legal Basis for Processing Personal Data under GDPR</H2>
      <P>We may process Personal Data under the following conditions:</P>
      <Ul>
        <li>
          <Bold>Consent:</Bold> You have given Your consent for processing
          Personal Data for one or more specific purposes.
        </li>
        <li>
          <Bold>Performance of a contract:</Bold> Provision of Personal Data is
          necessary for the performance of an agreement with You and/or for any
          pre-contractual obligations thereof.
        </li>
        <li>
          <Bold>Legal obligations:</Bold> Processing Personal Data is necessary
          for compliance with a legal obligation to which the Company is
          subject.
        </li>
        <li>
          <Bold>Vital interests:</Bold> Processing Personal Data is necessary in
          order to protect Your vital interests or of another natural person.
        </li>
        <li>
          <Bold>Public interests:</Bold> Processing Personal Data is related to
          a task that is carried out in the public interest or in the exercise
          of official authority vested in the Company.
        </li>
        <li>
          <Bold>Legitimate interests:</Bold> Processing Personal Data is
          necessary for the purposes of the legitimate interests pursued by the
          Company.
        </li>
      </Ul>
      <P>
        In any case, the Company will gladly help to clarify the specific legal
        basis that applies to the processing, and in particular whether the
        provision of Personal Data is a statutory or contractual requirement, or
        a requirement necessary to enter into a contract.
      </P>
      <H2>Your Rights under the GDPR</H2>
      <P>
        The Company undertakes to respect the confidentiality of Your Personal
        Data and to guarantee You can exercise Your rights.
      </P>
      <P>
        You have the right under this Privacy Policy, and by law if You are
        within the EU, to:
      </P>
      <Ul>
        <li>
          <Bold>Request access to Your Personal Data.</Bold> The right to
          access, update or delete the information We have on You. Whenever made
          possible, you can access, update or request deletion of Your Personal
          Data directly within Your account settings section. If you are unable
          to perform these actions yourself, please contact Us to assist You.
          This also enables You to receive a copy of the Personal Data We hold
          about You.
        </li>
        <li>
          <Bold>
            Request correction of the Personal Data that We hold about You.
          </Bold>{" "}
          You have the right to to have any incomplete or inaccurate information
          We hold about You corrected.
        </li>
        <li>
          <Bold>Object to processing of Your Personal Data.</Bold> This right
          exists where We are relying on a legitimate interest as the legal
          basis for Our processing and there is something about Your particular
          situation, which makes You want to object to our processing of Your
          Personal Data on this ground. You also have the right to object where
          We are processing Your Personal Data for direct marketing purposes.
        </li>
        <li>
          <Bold>Request erasure of Your Personal Data.</Bold> You have the right
          to ask Us to delete or remove Personal Data when there is no good
          reason for Us to continue processing it.
        </li>
        <li>
          <Bold>Request the transfer of Your Personal Data.</Bold> We will
          provide to You, or to a third-party You have chosen, Your Personal
          Data in a structured, commonly used, machine-readable format. Please
          note that this right only applies to automated information which You
          initially provided consent for Us to use or where We used the
          information to perform a contract with You.
        </li>
        <li>
          <Bold>Withdraw Your consent.</Bold> You have the right to withdraw
          Your consent on using your Personal Data. If You withdraw Your
          consent, We may not be able to provide You with access to certain
          specific functionalities of the Service.
        </li>
      </Ul>
      <H2>Exercising of Your GDPR Data Protection Rights</H2>
      <P>
        You may exercise Your rights of access, rectification, cancellation and
        opposition by contacting Us. Please note that we may ask You to verify
        Your identity before responding to such requests. If You make a request,
        We will try our best to respond to You as soon as possible.
      </P>
      <P>
        You have the right to complain to a Data Protection Authority about Our
        collection and use of Your Personal Data. For more information, if You
        are in the European Economic Area (EEA), please contact Your local data
        protection authority in the EEA.
      </P>
      <H1>CCPA Privacy</H1>
      <H2>Your Rights under the CCPA</H2>
      <P>
        Under this Privacy Policy, and by law if You are a resident of
        California, You have the following rights:
      </P>
      <Ul>
        <li>
          <Bold>The right to notice.</Bold> You must be properly notified which
          categories of Personal Data are being collected and the purposes for
          which the Personal Data is being used.
        </li>
        <li>
          <Bold>The right to access / the right to request.</Bold> The CCPA
          permits You to request and obtain from the Company information
          regarding the disclosure of Your Personal Data that has been collected
          in the past 12 months by the Company or its subsidiaries to a
          third-party for the third party's direct marketing purposes.
        </li>
        <li>
          <Bold>The right to say no to the sale of Personal Data.</Bold> You
          also have the right to ask the Company not to sell Your Personal Data
          to third parties. You can submit such a request by visiting our
          &quot;Do Not Sell My Personal Information&quot; section or web page.
        </li>
        <li>
          <Bold>The right to know about Your Personal Data.</Bold> You have the
          right to request and obtain from the Company information regarding the
          disclosure of the following:
          <Ul>
            <li>The categories of Personal Data collected</li>
            <li>The sources from which the Personal Data was collected</li>
            <li>
              The business or commercial purpose for collecting or selling the
              Personal Data
            </li>
            <li>
              Categories of third parties with whom We share Personal Data
            </li>
            <li>The specific pieces of Personal Data we collected about You</li>
          </Ul>
        </li>
        <li>
          <Bold>The right to delete Personal Data.</Bold> You also have the
          right to request the deletion of Your Personal Data that have been
          collected in the past 12 months.
        </li>
        <li>
          <Bold>The right not to be discriminated against.</Bold> You have the
          right not to be discriminated against for exercising any of Your
          Consumer's rights, including by:
          <Ul>
            <li>Denying goods or services to You</li>
            <li>
              Charging different prices or rates for goods or services,
              including the use of discounts or other benefits or imposing
              penalties
            </li>
            <li>
              Providing a different level or quality of goods or services to You
            </li>
            <li>
              Suggesting that You will receive a different price or rate for
              goods or services or a different level or quality of goods or
              services.
            </li>
          </Ul>
        </li>
      </Ul>
      <H2>Exercising Your CCPA Data Protection Rights</H2>
      <P>
        In order to exercise any of Your rights under the CCPA, and if you are a
        California resident, You can email or call us or visit our &quot;Do Not
        Sell My Personal Information&quot; section or web page.
      </P>
      <P>
        The Company will disclose and deliver the required information free of
        charge within 45 days of receiving Your verifiable request. The time
        period to provide the required information may be extended once by an
        additional 45 days when reasonable necessary and with prior notice.
      </P>
      <H2>Do Not Sell My Personal Information</H2>
      <P>
        We do not sell personal information. However, the Service Providers we
        partner with (for example, our advertising partners) may use technology
        on the Service that &quot;sells&quot; personal information as defined by
        the CCPA law.
      </P>
      <P>
        If you wish to opt out of the use of your personal information for
        interest-based advertising purposes and these potential sales as defined
        under CCPA law, you may do so by following the instructions below.
      </P>
      <P>
        Please note that any opt out is specific to the browser You use. You may
        need to opt out on every browser that you use.
      </P>
      <H3>Website</H3>
      <P>
        You can opt out of receiving ads that are personalized as served by our
        Service Providers by following our instructions presented on the
        Service:
      </P>
      <Ul>
        <li>From Our &quot;Cookie Consent&quot; notice banner</li>
        <li>Or from Our &quot;CCPA Opt-out&quot; notice banner</li>
        <li>
          Or from Our &quot;Do Not Sell My Personal Information&quot; notice
          banner
        </li>
        <li>
          Or from Our &quot;Do Not Sell My Personal Information&quot; link
        </li>
      </Ul>
      <P>
        The opt out will place a cookie on Your computer that is unique to the
        browser You use to opt out. If you change browsers or delete the cookies
        saved by your browser, you will need to opt out again.
      </P>
      <H3>Mobile Devices</H3>
      <P>
        Your mobile device may give you the ability to opt out of the use of
        information about the apps you use in order to serve you ads that are
        targeted to your interests:
      </P>
      <Ul>
        <li>
          &quot;Opt out of Interest-Based Ads&quot; or &quot;Opt out of Ads
          Personalization&quot; on Android devices
        </li>
        <li>&quot;Limit Ad Tracking&quot; on iOS devices</li>
      </Ul>
      <P>
        You can also stop the collection of location information from Your
        mobile device by changing the preferences on your mobile device.
      </P>
      <H1>
        &quot;Do Not Track&quot; Policy as Required by California Online Privacy
        Protection Act (CalOPPA)
      </H1>
      <P>Our Service does not respond to Do Not Track signals.</P>
      <P>
        However, some third party websites do keep track of Your browsing
        activities. If You are visiting such websites, You can set Your
        preferences in Your web browser to inform websites that You do not want
        to be tracked. You can enable or disable DNT by visiting the preferences
        or settings page of Your web browser.
      </P>
      <H1>Your California Privacy Rights (California's Shine the Light law)</H1>
      <P>
        Under California Civil Code Section 1798 (California's Shine the Light
        law), California residents with an established business relationship
        with us can request information once a year about sharing their Personal
        Data with third parties for the third parties' direct marketing
        purposes.
      </P>
      <P>
        If you'd like to request more information under the California Shine the
        Light law, and if you are a California resident, You can contact Us
        using the contact information provided below.
      </P>
      <H1>
        California Privacy Rights for Minor Users (California Business and
        Professions Code Section 22581)
      </H1>
      <P>
        California Business and Professions Code section 22581 allow California
        residents under the age of 18 who are registered users of online sites,
        services or applications to request and obtain removal of content or
        information they have publicly posted.
      </P>
      <P>
        To request removal of such data, and if you are a California resident,
        You can contact Us using the contact information provided below, and
        include the email address associated with Your account.
      </P>
      <P>
        Be aware that Your request does not guarantee complete or comprehensive
        removal of content or information posted online and that the law may not
        permit or require removal in certain circumstances.
      </P>
      <H1>Links to Other Websites</H1>
      <P>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </P>
      <P>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </P>
      <H1>Changes to this Privacy Policy</H1>
      <P>
        We may update our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </P>
      <P>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the &quot;Last
        updated&quot; date at the top of this Privacy Policy.
      </P>
      <P>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </P>
      <H1>Contact Us</H1>
      <P>
        If you have any questions about this Privacy Policy, You can contact us:
      </P>
      <Ul>
        <li>By email: help@torchlight.press</li>
      </Ul>
    </Wrapper>
  )
}

export default PrivacyPolicy
